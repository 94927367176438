import Vue from 'vue'
import VueRouter from 'vue-router'

// ATLANTA
import Survey from '../views/Survey.vue';
import SurveyBur from '../views/Survey-Bur.vue';
import SurveyEth from '../views/Survey-Eth.vue';
import SurveyFar from '../views/Survey-Far.vue';
import SurveyFre from '../views/Survey-Fre.vue';
import SurveyNep from '../views/Survey-Nep.vue';
import SurveySpa from '../views/Survey-Spa.vue';
import SurveyVie from '../views/Survey-Vie.vue';

//Eagan
import SurveyEagan from '../views/Survey-Eagan.vue'
import SurveyEaganSpanish from '../views/Survey-Eagan-Spanish.vue'
//Buffalo
import SurveyBuffalo from '../views/Survey-Buffalo.vue'

//Bonner
import SurveyBonner from '../views/Survey-Bonner.vue'
import SurveyBonnerSpanish from '../views/Survey-Bonner-Spanish.vue'

//Dallas
import SurveyDallas from '../views/Survey-Dallas.vue'
import SurveyDallasSpanish from '../views/Survey-Dallas-Spanish.vue'

// Ogden
import SurveyOgden from '../views/Survey-Ogden-English'
import SurveyOgdenSpanish from '../views/Survey-Ogden-Spanish'


Vue.use(VueRouter)

const routes = [{
    path: '/AI/English',
    name: 'English',
    component: Survey,
  },
  {
    path: '/AI/Burmese',
    name: 'Burmese',
    component: SurveyBur,
  },
  {
    path: '/AI/Amharic',
    name: 'Ethiopian',
    component: SurveyEth,
  },
  {
    path: '/AI/Farsi',
    name: 'Farsi',
    component: SurveyFar,
  },
  {
    path: '/AI/French',
    name: 'French',
    component: SurveyFre,
  },
  {
    path: '/AI/Nepali',
    name: 'Nepalese',
    component: SurveyNep,
  },
  {
    path: '/AI/Spanish',
    name: 'Spanish',
    component: SurveySpa,
  },
  {
    path: '/AI/Vietnamese',
    name: 'Vietnamese',
    component: SurveyVie,
  },
  {
    path: '/Eagan',
    name: 'Eagan',
    component: SurveyEagan,
  },
  {
    path: '/Eagan/Spanish',
    name: 'Eagan Spanish',
    component: SurveyEaganSpanish,
  },
  {
    path: '/Bonner',
    name: 'Bonner',
    component: SurveyBonner,
  },
  {
    path: '/Bonner/Spanish',
    name: 'Bonner Spanish',
    component: SurveyBonnerSpanish,
  },
  {
    path: '/Buffalo',
    name: 'Buffalo',
    component: SurveyBuffalo,
  },
  {
    path: '/Dallas/Spanish',
    name: 'Dallas Spanish',
    component: SurveyDallasSpanish,
  },
  {
    path: '/Dallas',
    name: 'Dallas',
    component: SurveyDallas,
  },
  {
    path: '/Ogden/Spanish',
    name: 'Ogden Spanish',
    component: SurveyOgdenSpanish,
  },
  {
    path: '/Ogden',
    name: 'Ogden',
    component: SurveyOgden,
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
