<template>
  <div id="app">
    <iframe
      id="typeform-full"
      width="100%"
      height="100%"
      frameborder="0"
      allow="camera; microphone; autoplay; encrypted-media;"
      :src="'https://form.typeform.com/to/KmVRtUiT#id=' + id"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.query.id,
    };
  },
};
</script>
